<template>
  <div>
    <md-dialog :md-active="patchDialogOpen" v-if="patchFields">
      <md-dialog-title>{{ patchDialogTitle }}</md-dialog-title>
      <md-dialog-content style="width: 500px">
        <form>
          <md-field v-for="(field, index) in (Object.entries(patchFields))" :key="index">
            <label>{{ field[0] }}</label>
            <md-input v-model="data[patchDialogID][field[1]]"></md-input>
          </md-field>
          <md-switch v-model="data[patchDialogID].published">Published</md-switch>
        </form>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="$router.go()">Close</md-button>
        <md-button class="md-primary" @click="patchEntry">Save</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-table v-if="data.length">
      <md-table-toolbar>
        <h1 class="md-title">{{ title }}</h1>
      </md-table-toolbar>
      <md-table-row>
        <md-table-head md-numeric v-if="showIndex">Index</md-table-head>
        <md-table-head md-numeric v-if="idColumn">ID</md-table-head>
        <md-table-head v-for="c in Object.keys(columns)" v-bind:key="c">{{ c }}</md-table-head>
        <md-table-head v-if="publishOption">Change Published Status</md-table-head>
        <md-table-head v-if="patchPath">Edit</md-table-head>
        <md-table-head>Delete</md-table-head>
      </md-table-row>

      <md-table-row v-for="(item, index) in data" v-bind:key="index">
        <md-table-cell md-numeric v-if="showIndex">{{ index }}</md-table-cell>
        <md-table-cell md-numeric v-if="idColumn">{{ item[idColumn] }}</md-table-cell>
        <md-table-cell v-for="c in Object.values(columns)" v-bind:key="c">{{ item[c] }}</md-table-cell>
        <md-table-cell v-if="publishOption">
          <md-button @click="publishEntry(item._id, !item.published)">
            <md-icon>settings</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell style="align-content: flex-start" v-if="patchPath">
          <md-button @click="preparePatchDialog(index)">
            <md-icon>manage_accounts</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell>
          <md-button @click="deleteEntry(item._id)">
            <md-icon class="md-accent">delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <div v-else>
      Nothing here to show :)
    </div>
  </div>
</template>
<script>

import {axiosDelete, axiosGet, axiosPatch} from "@/utils/axiosWrapper";
import axios from "axios";

export default {
  data() {
    return {
      data: [],
      patchDialogOpen: false,
      patchDialogID: 0
    }
  },
  props: {
    // Titles
    title: {
      type: String,
      default: 'Data Entries'
    },
    patchDialogTitle: {
      type: String,
      default: 'Edit'
    },


    // Paths
    loadPath: {
      type: String,
      default: null
    },
    deletePath: {
      type: String,
      default: null
    },
    patchPath: {
      type: String,
      default: null
    },
    publishPath: {
      type: String,
      default: null
    },

    // Options
    showIndex: {
      type: Boolean,
      default: true
    },
    idColumn: {
      type: String,
      default: ''
    },
    publishOption: {
      type: Boolean,
      default: false
    },
    log: {
      type: Boolean,
      default: false
    },

    // Columns and Fields
    columns: {
      type: Object,
      default: () => {
      }
    },
    patchFields: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    deleteEntry: async function (id) {
      if (this.deletePath === null) {
        alert('This function is not set up, please contact Ruben to enable it.')
        return
      }
      if (this.log)
        await axios.delete(this.deletePath + id)
      else
        await axiosDelete(this.deletePath + id)

      if (this.log)
        this.data = (await axios.get(this.loadPath)).data
      else
        this.data = (await axiosGet(this.loadPath)).data
    },
    preparePatchDialog: function (id) {
      if (this.patchPath) {
        this.patchDialogOpen = true
        this.patchDialogID = id
      }
    },
    patchEntry: async function () {
      if (this.patchPath === null) {
        alert('This function is not set up, please contact Ruben to enable it.')
        return
      }
      let updatedData = {
        "_id": this.data[this.patchDialogID]["_id"],
        "published": this.data[this.patchDialogID]["published"]
      }
      for (let d of Object.values(this.patchFields)) {
        updatedData = Object.assign(updatedData, {[d]: this.data[this.patchDialogID][d]})
      }
      if (this.log)
        axios.patch(this.patchPath, updatedData)
            .then(() => {
              this.patchDialogOpen = false
            })
            .catch(e => {
              console.warn(e)
            })
      else
        axiosPatch(this.patchPath, updatedData)
            .then(() => {
              this.patchDialogOpen = false
            })
            .catch(e => {
              console.warn(e)
            })
    },
    publishEntry: async function (id, published) {
      if (this.publishPath === null) {
        alert('This function is not set up, please contact Ruben to enable it.')
        return
      }
      let publish
      if (this.log)
        publish = await axios.patch(this.deletePath + '/publish/', {
          _id: id,
          published: published
        })
      else
        publish = await axiosPatch(this.deletePath + '/publish/', {
          _id: id,
          published: published
        })

      console.log(publish)
      if (this.log)
        this.data = (await axios.get(this.loadPath)).data
      else
        this.data = (await axiosGet(this.loadPath)).data
    },
  },
  mounted: async function () {
    if (this.loadPath === null) {
      alert(`Loading Data for ${this.title} is not set up, please contact Ruben to enable it.`)
      return
    }

    if (this.log)
      this.data = (await axios.get(this.loadPath)).data
    else
      this.data = (await axiosGet(this.loadPath)).data
  }
}
</script>
